import React,{useState,useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import * as Constant from '../Constant'
import samplepdf from '../../assets/sample.pdf'
import samplepdfimg from '../../assets/Screenshot_1.png'

function Samples_widget() {
  const [demo,setDemos] = useState();
  const [demotitle,setDemotitle] = useState();
  const [demosubsubject,setSubSubject] = useState();
  const [demoanswer,setAnswer] = useState();
  const {id} =  useParams();

  const getDemo = async () => {

    await fetch(Constant.DemosDetails + '/' + id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data.data[0].title)
          setDemos(data.data[0])
          setDemotitle(data.data[0].title)
          setSubSubject(data.data[0].sub_subject)
          setAnswer(data.data[0].answer)
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  useEffect(() => {
    getDemo();
  },[]);

  return (
    <>
     <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h3 class="display-3 font-weight-bold text-white">
          {demotitle}
          </h3>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">
              <Link class="text-white" >
                Sample's
              </Link>
            </p>
            <p class="m-0 px-2">/ {demotitle}</p>
            <p class="m-0"></p>
          </div>
        </div>
      </div>
      <section className="blog-posts-area section-gap" style={{ textAlign: 'justify' }}>
  <div className="container">
    <div className="row d-flex justify-content-center text-center"></div>
    <div id="right-hand-side-container">
      <div className="single-widget category-widget shadow">
        <h4 className="ml-3"></h4>
        <div className="single-post single-heading">
          <div className="post mb-4">
            <h3>{demotitle}</h3>
            <p>{demosubsubject}</p>
            <p dangerouslySetInnerHTML={{ __html: demoanswer }}></p>
            <div className="demo-container" style={{ position: 'relative', textAlign: 'center' }}>
              {/* Blurred PDF or image */}
              <img
                src={samplepdfimg}
                alt="Demo"
                style={{
                  width: '50%',
                  filter: 'blur(5px)',
                  opacity: 0.8,
                }}
              />
              {/* Centered Order Now Button */}
              <button
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  padding: '10px 20px',
                  backgroundColor: '#ff6600',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                }}
                onClick={() => window.location.href = '/contactus'}
              >
                Order Now
              </button>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Samples_widget
