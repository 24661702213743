export const BaseUrlApi = "https://admin.assignmentserviceaustralia.com/api/"
export const BaseUrlApi2 = "https://admin.assignmentserviceaustralia.com/"
// export const BaseUrlApi = "http://localhost/asa_backend/api/"
// export const BaseUrlApi2 = "http://localhost/asa_backend/"

export const ContactUrl = BaseUrlApi + 'contact'
export const ContactusUrl = BaseUrlApi + 'contactus'

export const AssignmentUrl = BaseUrlApi + 'assignment'

export const CountryUrl = BaseUrlApi + 'country'

export const ColorUrl = BaseUrlApi + 'color_get'

export const TestimonialUrl = BaseUrlApi + 'testimonial'

export const Subjects = BaseUrlApi + 'subject'

export const Demos = BaseUrlApi + 'get_demo'

export const DemosDetails = BaseUrlApi + 'get_demo_details'

export const ImageUrl = BaseUrlApi2 + '/public/images/'

// export const BaseUrl = "http://localhost:5001/"
export const BaseUrl = "https://assignmentserviceaustralia.com/"
